// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@hotwired/turbo-rails");
import "regenerator-runtime/runtime";

// Images
// Reference them with the image_pack_tag helper in views (e.g < %= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

// Stylesheets
import '../stylesheets/application.scss';

// Stimulus controllers
import "javascript/controllers"

// NPM libs
// NOTE: We expose some libs, e.g., jquery via expose-loader in config/webpack/environment.js
import "jquery";
import "@fortawesome/fontawesome-free/js/all.js";
