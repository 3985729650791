// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.
import { Application } from 'stimulus';
import { definitionsFromContext } from 'stimulus/webpack-helpers';
import { registerAllControllers } from "@niiwin/core";

const application = Application.start();
const context = require.context('./', true, /_controller\.js$/);
// Import and register all Niiwin Stimulus controllers (first)
registerAllControllers(application);

// Load this app's controllers (second, in case they override Niiwin controllers)
application.load(definitionsFromContext(context));
